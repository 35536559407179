import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import EditNoteIcon from "@mui/icons-material/EditNote";
// import BarChart from "../components/Chart/BarChart";
import "react-circular-progressbar/dist/styles.css";
import Navbar from "../components/Navbar";
import ReactCircular from "../components/Chart/ReactCircular";
import Cards from "../components/Cards/Cards";
import React from "react";
import CombinationChart from "../components/Chart/CombinationChart";
import Tables from "../components/Cards/Tables";
// import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import GraphChart from "../components/Chart/GraphChart";
export default function Dashboard() {
  return (
    <>
      <Navbar title="Dashboard" />
      <Box height={60} />
      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 1,
            backgroundColor: "#f2f2f2",
            position: "relative",
            boxShadow: "inset 2px 0 19px -10px rgba(0, 0, 0, 0.5)",
            paddingTop: "40px",
            minHeight: "100vh",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Stack spacing={2} direction="row">
                <Cards
                  Icon={<CreditCardIcon />}
                  text={"$500.00"}
                  text2={"Total Earning"}
                  bgColor="#81ce6d"
                />
                <Cards
                  Icon={<LocalMallIcon />}
                  text={"$900.00"}
                  text2={"Total Order"}
                  bgColor="#e85d04"
                />
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Cards
                Icon={<EditNoteIcon />}
                text={"$500.00"}
                text2={"Total Complaints "}
                bgColor={"#19647e"}
              />
            </Grid>
          </Grid>
          {/* Second Grid container */}
          <Box height={60} />
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Card sx={{ minHeight: "45vh" }}>
                <GraphChart />
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Stack spacing={2} direction="column" >
                <ReactCircular value={60} text={"60"}  />
                <ReactCircular value={50} text={"50"} />
                <ReactCircular value={80} text={"80"} />
              </Stack>
            </Grid>
          </Grid>
          <Box height={50} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Tables />
            </Grid>
            <Grid item xs={6}>
              <CombinationChart />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
