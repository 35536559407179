import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const data = [
  { name: "Week 1", lastMonth: 400, thisMonth: 300 },
  { name: "Week 2", lastMonth: 300, thisMonth: 200 },
  { name: "Week 3", lastMonth: 500, thisMonth: 700 },
  { name: "Week 4", lastMonth: 400, thisMonth: 500 },
  { name: "Week 5", lastMonth: 300, thisMonth: 400 },
];

const  GraphChart= () => {
  return (
    <div style={{ width: "100%", height: 480 }}>
      <ResponsiveContainer>
        <AreaChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id="colorThisMonth" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorLastMonth" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="thisMonth"
            stroke="#8884d8"
            fillOpacity={1}
            fill="url(#colorThisMonth)"
          />
          <Area
            type="monotone"
            dataKey="lastMonth"
            stroke="#82ca9d"
            fillOpacity={1}
            fill="url(#colorLastMonth)"
          />
        </AreaChart>
      </ResponsiveContainer>
      {/* <div style={{ textAlign: "center", paddingTop: "10px" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ margin: "0 10px" }}>
            <span style={{ color: "#8884d8" }}>● This Month</span>
            <p>$4,785</p>
          </div>
          <div style={{ margin: "0 10px" }}>
            <span style={{ color: "#82ca9d" }}>● Last Month</span>
            <p>$4,029</p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default GraphChart;
