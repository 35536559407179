import { Card, CardContent, Typography } from "@mui/material";
import React from "react";

export default function Cards({ Icon, text, text2, bgColor }) {
  return (
    <Card 
      sx={{ 
        minWidth: "49%", 
        height: 150, 
        backgroundColor: bgColor || 'white' 
      }}
    >
      <CardContent>
        {Icon}
        <Typography gutterBottom variant="h5" component="div"  sx={{ color: "white" }} >
          {text}
        </Typography>
        <Typography
          gutterBottom
          variant="body2"
          component="div"
          sx={{ color: "white" }}
        >
          {text2}
        </Typography>
      </CardContent>
    </Card>
  );
}
