import AWS from 'aws-sdk';


const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,

});

export const uploadFileToS3 = async (file, fileName) => {
  const params = {
    Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
    Key: fileName, 
    Body: file,
    ContentType: file.type, 
  };

  try {
    const data = await s3.upload(params).promise();
    return data; 
  } catch (error) {
    console.error("Error uploading file to S3:", error);
    throw new Error("File upload failed");
  }
};
