import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Box from '@mui/material/Box';
import {
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
  Grid,
  Divider,
} from '@mui/material';
import Navbar from "../components/Navbar";

// Comprehensive ONDC category data
const categories = [
  {
    id: 1,
    name: 'Food and Beverages',
    subcategories: ['Grocery', 'Restaurants', 'Fast Food', 'Beverages'],
  },
  {
    id: 2,
    name: 'Electronics',
    subcategories: ['Mobiles', 'Laptops', 'Accessories', 'Home Appliances'],
  },
  {
    id: 3,
    name: 'Clothing',
    subcategories: ['Men\'s Wear', 'Women\'s Wear', 'Kids\' Wear', 'Sportswear'],
  },
  {
    id: 4,
    name: 'Footwear',
    subcategories: ['Sneakers', 'Boots', 'Sandals', 'Loafers'],
  },
  {
    id: 5,
    name: 'Home and Kitchen',
    subcategories: ['Furniture', 'Kitchenware', 'Decor', 'Bedding'],
  },
  {
    id: 6,
    name: 'Health and Personal Care',
    subcategories: ['Supplements', 'Skincare', 'Hair Care', 'Hygiene Products'],
  },
  {
    id: 7,
    name: 'Books and Stationery',
    subcategories: ['Fiction', 'Non-Fiction', 'Educational', 'Office Supplies'],
  },
];

export default function Category() {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);

  const handleSelectCategory = (category) => {
    setSelectedCategory(category);
    setSelectedSubcategory(null); // Reset subcategory when a new category is selected
  };

  const handleSelectSubcategory = (subcategory) => {
    setSelectedSubcategory(subcategory);
  };

  return (
    <div>
      <Navbar title="Category" />
      <Box height={80} />
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#1976d2' }}>
            ONDC Categories
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#e3f2fd' }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                  Category List
                </Typography>
                <List>
                  {categories.map((category) => (
                    <ListItem key={category.id}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => handleSelectCategory(category)}
                        sx={{
                          justifyContent: 'flex-start',
                          borderRadius: 5,
                          '&:hover': {
                            backgroundColor: '#1565c0',
                          },
                        }}
                      >
                        <ListItemText primary={category.name} />
                      </Button>
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
            <Grid item xs={12} md={8}>
              <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#f9f9f9' }}>
                {selectedCategory ? (
                  <>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      Subcategories of {selectedCategory.name}
                    </Typography>
                    <Divider sx={{ margin: '10px 0' }} />
                    <List>
                      {selectedCategory.subcategories.map((sub, index) => (
                        <ListItem key={index}>
                          <Button
                            fullWidth
                            variant="outlined"
                            color="secondary"
                            onClick={() => handleSelectSubcategory(sub)}
                            sx={{
                              justifyContent: 'flex-start',
                              borderRadius: 5,
                              '&:hover': {
                                backgroundColor: '#d1c4e9',
                              },
                            }}
                          >
                            <ListItemText primary={sub} />
                          </Button>
                        </ListItem>
                      ))}
                    </List>
                    {selectedSubcategory && (
                      <>
                        <Divider sx={{ margin: '20px 0' }} />
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                          Selected Subcategory: {selectedSubcategory}
                        </Typography>
                      </>
                    )}
                  </>
                ) : (
                  <Typography variant="h6" sx={{ color: '#757575' }}>
                    Select a category to see subcategories
                  </Typography>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
}
