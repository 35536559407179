import axios from 'axios';
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_API || "http://localhost:8080/dashboard/";


export const fetchData = async () => {
  try {
    console.log()
    const response = await axios.get( `${API_URL}/data`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export const  loginapi = async ({ email, password, dispatch, login, navigate }) => {
  dispatch({ type: "REQUEST_LOGIN" });
  try {
     console.log(`${API_URL}adminLogin`)
    let response = await fetch(`${API_URL}adminLogin`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, password }),
    });
    response = await response.json();

    if (response.success) {
      dispatch({ type: "LOGIN_SUCCESS", payload: response.data });
      toast.success(response.message);
      Cookies.set("token", response.token, { expires: 7, secure: true });
      login();
      navigate("/dashboard");
    } else {
      toast.error(response.message);
      dispatch({ type: "LOGIN_ERROR", error: response.message });
    }
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error: error.message });
  }
};



export const registerSellerAPI = async (formData) => {
  try {
    const response = await fetch(`${API_URL}adminRegister`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error during registration:", error);
    throw error;
  }
};




export const fetchAllProducts = async () => {
  try {
    const token = Cookies.get("token");
    const response = await axios.get("http://localhost:8080/product/products", {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message || "Error fetching products");
  }
};



export const searchProducts = async (searchQuery, stock) => {
  try {
    const token = Cookies.get("token");
    const response = await axios.get(
      `http://localhost:8080/product/search?name=${searchQuery}&stock=${stock}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message || "Error searching products");
  }
};
export const createProduct = async (formData) => {
  try {
    const token = Cookies.get("token");
    const response = await fetch("http://localhost:8080/product/ProductCreate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `${token}`,
      },
      body: JSON.stringify(formData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message || "Error creating product");
  }
};



export const updateStoreDetails = async (storePayload) => {
  try {
    const token = Cookies.get("token");
    const response = await fetch(`${API_URL}adminStoreDetails`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `${token}`,
      },
      body: JSON.stringify(storePayload),
    });

    const data = await response.json();
    return data; 
  } catch (error) {
    console.error("API error:", error);
    throw error; 
  }
};




export const fetchProfileData = async () => {
  const token = Cookies.get("token");
  if (!token) {
    throw new Error("No token found");
  }

  try {
    const response = await fetch(`${API_URL}getAdminProfile`, {
      method: "GET",
      headers: {
        authorization: `${token}`,
      },
    });

    const data = await response.json();

    if (data.success === true) {
      return data.data.OrganizationDetails;
    } else {
      throw new Error("Failed to fetch profile");
    }
  } catch (error) {
    throw new Error(`Error fetching profile: ${error.message}`);
  }
};


export const updateStore = async (storePayload) => {
  const token = Cookies.get("token");
  if (!token) {
    throw new Error("No token found");
  }

  try {
    const response = await fetch(`${API_URL}adminStoreUpdate`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: `${token}`,
      },
      body: JSON.stringify(storePayload),
    });

    const data = await response.json();

    if (data.success === true) {
      return data;
    } else {
      throw new Error("Failed to update store");
    }
  } catch (error) {
    throw new Error(`Error updating store: ${error.message}`);
  }
};





export const fetchUserData = async () => {
  const token = Cookies.get("token");
  if (!token) {
    throw new Error("No token found");
  }

  try {
    const response = await fetch(`${API_URL}getAdminProfile`, {
      method: "GET",
      headers: {
        authorization: `${token}`,
      },
    });

    const data = await response.json();
    if (data.success === true) {
      return data.data;
    } else {
      throw new Error("Failed to fetch profile");
    }
  } catch (error) {
    throw new Error(`Error fetching profile: ${error.message}`);
  }
};

// Function to update the profile data
export const updateProfileData = async (formData) => {
  const token = Cookies.get("token");
  if (!token) {
    throw new Error("No token found");
  }

  try {
    const response = await fetch(`${API_URL}adminProfileUpdate`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: `${token}`,
      },
      body: JSON.stringify(formData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(`Error updating profile: ${error.message}`);
  }
};




export const fetchProductData = async (productId) => {
  const token = Cookies.get("token");
  if (!token) {
    throw new Error("No token found");
  }

  try {
    const response = await axios.get(`http://localhost:8080/product/getproduct/${productId}`, {
      headers: {
        authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Error fetching product data: " + error.message);
  }
};


export const updateProductData = async (productId, formData) => {
  const token = Cookies.get("token");
  if (!token) {
    throw new Error("No token found");
  }

  try {
    const response = await fetch(`http://localhost:8080/product/products/${productId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: `${token}`,
      },
      body: JSON.stringify(formData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Error updating product data: " + error.message);
  }
};