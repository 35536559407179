import Sidebar from "../components/Sidebar";
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  
  styled,
} from '@mui/material';
import Navbar from "../components/Navbar";
const brands = [
  { id: 1, name: 'Nike' },
  { id: 2, name: 'Adidas' },
  { id: 3, name: 'Puma' },
  { id: 4, name: 'Reebok' },
  { id: 5, name: 'Under Armour' },
  { id: 6, name: 'New Balance' },
  { id: 7, name: 'Asics' },
  { id: 8, name: 'Skechers' },
  { id: 9, name: 'Fila' },
  { id: 10, name: 'Converse' },
  { id: 11, name: 'Vans' },
  { id: 12, name: 'Champion' },
];

// Styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.common.white,
  fontWeight: 'bold',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

export default function Brands() {
  const [selectedBrands, setSelectedBrands] = useState([]);

  const handleSelectBrand = (id) => {
    setSelectedBrands((prev) =>
      prev.includes(id) ? prev.filter((brandId) => brandId !== id) : [...prev, id]
    );
  };

  return (
    <div>
     <Navbar title="Brands" /> 
      <Box height={60} />
      <Box sx={{ display: 'flex' }}>
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          {/* <Typography variant="h4" gutterBottom>
            Brand Management
          </Typography> */}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={selectedBrands.length > 0 && selectedBrands.length < brands.length}
                      checked={selectedBrands.length === brands.length}
                      onChange={() => {
                        if (selectedBrands.length === brands.length) {
                          setSelectedBrands([]);
                        } else {
                          setSelectedBrands(brands.map((brand) => brand.id));
                        }
                      }}
                    />
                  </TableCell>
                  <StyledTableCell>ID</StyledTableCell>
                  <StyledTableCell>Brand Name</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {brands.map((brand) => (
                  <StyledTableRow key={brand.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedBrands.includes(brand.id)}
                        onChange={() => handleSelectBrand(brand.id)}
                      />
                    </TableCell>
                    <TableCell>{brand.id}</TableCell>
                    <TableCell>{brand.name}</TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </div>
  );
}